import React from 'react';
import Logo from '../../Assets/Images/goidd.png';

const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center full-page-loader"
      style={{ height: '100vh' }}
    >
      <img src={Logo} alt="logo" width={100} />
    </div>
  );
};

export default Loader;
